import {
	doc,
	getDocs,
	getDoc,
	updateDoc,
	collection,
	deleteDoc,
	query,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { GET_PROFILE, GET_USERS, GET_FOLLOW } from "./actionTypes";
import { db } from "../../firebase-config";

export const fetchUserProfile = async (id) => {
	try {
		const userSnapshot = await getDoc(doc(db, "users", id));
		if (userSnapshot.exists()) {
			return userSnapshot.data();
		}
	} catch (error) {
		return console.log("her eis errro:", error);
	}
};

export const getUserProfile = (id) => async (dispatch) => {
	try {
		const userSnapshot = await getDoc(doc(db, "users", id));
		if (userSnapshot.exists()) {
			dispatch({
				type: GET_PROFILE,
				payload: userSnapshot.data(),
			});
		}
	} catch (error) {
		console.log("her eis errro:", error);
	}
};

// get all users
export const getUsers = () => async (dispatch) => {
	try {
		const usersSnapshot = await getDocs(collection(db, "users"));

		const usersList = usersSnapshot.docs.map((doc) => doc.data());
		dispatch({
			type: GET_USERS,
			payload: usersList,
		});
	} catch (error) {
		console.log("her eis errro:", error);
	}
};

// get all users
export const getUsersMeta = async () => {
	try {
		const usersSnapshot = await getDocs(collection(db, "users"));

		const usersList = usersSnapshot.docs.map((doc) => doc.data());

		const memberData = usersList.filter((user) => user.role === "member");

		const agencyData = usersList.filter((user) => user.role === "agent");
		return [
			{
				label: "Member",
				value: memberData?.length,
			},
			{
				label: "Agency",
				value: agencyData?.length,
			},
		];
	} catch (error) {
		console.log("her eis errro:", error);
	}
};

// get all user members
export const getAllUserFollowing = async (id) => {
	try {
		const memberColRef = collection(db, "members", id, "userMember");
		const q = query(memberColRef);
		const querySnapshot = await getDocs(q);

		const followingData = querySnapshot.docs
			.map((doc) => {
				if (doc.data()?.approval) {
					return null;
				}
				return doc?.data();
			})
			.filter((value) => value != null);

		return followingData;
	} catch (error) {
		console.log("her eis errro: &&&", error);
	}
};

// get all user members
export const getUserMembers = async (id) => {
	try {
		const memberColRef = collection(db, "members", id, "userMember");
		const q = query(memberColRef);
		const querySnapshot = await getDocs(q);

		const followingData = querySnapshot.docs
			.map((doc) => {
				if (!doc.data()?.approval) {
					return null;
				}
				return doc?.data();
			})
			.filter((value) => value != null);

		return followingData;
	} catch (error) {
		console.log("her eis errro: &&&", error);
	}
};
// get user following
export const getFollowing = (id, authId) => async (dispatch) => {
	try {
		const membersSnapshot = await getDoc(
			doc(db, "members", id, "userMember", authId)
		);
		dispatch({
			type: GET_FOLLOW,
			payload: membersSnapshot.data(),
		});
	} catch (error) {
		console.log("her eis errro: &&&", error);
	}
};

// const cityRef = doc(db, 'cities', 'BJ');
// setDoc(cityRef, { capital: true }, { merge: true });

// set( { users: [uid], }, { merge: true }, )

// var cityRef = db.collection('cities').doc('BJ');

// var setWithMerge = cityRef.set({
//     capital: true
// }, { merge: true });
export const approvalUser = async (id) => {
	const userRef = doc(db, "users", id);
	await updateDoc(userRef, {
		approval: true,
	});
	toast.success("Approval Successfully!!");
	await updateDoc(doc(db, "notifications", `${id}-newuser`), {
		approval: true,
	});
};

// update User Profile
export const updateUserProfile =
	({
		id,
		firstName,
		lastName,
		email,
		role,
		country = "Myanmar",
		phone = "",
		cardHolder = "",
		creditCard = "",
		bankName = "",
		amount = "",
	}) =>
	async (dispatch) => {
		try {
			const userRef = doc(db, "users", id);
			await updateDoc(userRef, {
				displayName: `${firstName} ${lastName}`,
				firstName,
				lastName,
				email,
				role,
				country,
				phone,
				cardHolder,
				creditCard,
				bankName,
				amount,
			});

			dispatch(getUserProfile(id));
		} catch (err) {
			console.log("udpate user; error", err);
			// dispatch({
			// 	type: UPDATE_PROFILE,
			// });
		}
	};

export const _deleteUser = (id) => async (dispatch) => {
	try {
		const userRef = doc(db, "users", id);
		await deleteDoc(userRef);

		dispatch(getUsers());
	} catch (error) {
		console.log("here is errro:", error);
	}
};
