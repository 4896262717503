import { Grid, Button, Card, Avatar, Typography, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { doc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { fetchUserProfile } from "../redux/actions/user";
import { db } from "../firebase-config";

const UserFollowList = ({ follow }) => {
	console.log("here is follow: data:", follow);
	const [recipient, setRecipient] = useState({});
	const [requester, setRequester] = useState({});
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		const Recipient = async () => {
			const data = await fetchUserProfile(follow?.recipient);
			setRecipient(data);
		};
		Recipient();
		// eslint-disable-next-line
	}, [follow?.recipient]);

	useEffect(() => {
		const Requester = async () => {
			const data = await fetchUserProfile(follow?.requester);
			setRequester(data);
		};
		Requester();
		// eslint-disable-next-line
	}, [follow?.requester]);

	const handleReject = async () => {
		await deleteDoc(
			doc(db, "members", recipient?.uid, "userMember", requester?.uid)
		);

		setTimeout(() => {
			window.location.reload(false);
		}, 1000);
	};

	const handleApproval = async () => {
		setLoading(true);
		await updateDoc(
			doc(db, "members", recipient?.uid, "userMember", requester?.uid),
			{
				approval: true,
				recipient: recipient?.uid,
				requester: requester?.uid,
				status: "joined",
			}
		);
		const recipientRef = doc(db, "users", recipient?.uid);
		const requesterRef = doc(db, "users", requester?.uid);
		const recipientSnapshot = await getDoc(
			doc(db, "users", recipient?.uid)
		);
		const requesterSnapshot = await getDoc(
			doc(db, "users", requester?.uid)
		);
		await updateDoc(recipientRef, {
			members: recipientSnapshot?.data().members
				? recipientSnapshot?.data().members + 1
				: 1,
			role: "agent",
		});
		await updateDoc(requesterRef, {
			joins: requesterSnapshot?.data().joins
				? requesterSnapshot?.data().joins + 1
				: 1,
		});

		await updateDoc(
			doc(
				db,
				"notifications",
				`${recipient?.uid}-join-${requester?.uid}`
			),
			{
				approval: true,
			}
		);
		setLoading(false);
		toast.success("Approval Successfully!");
		setTimeout(() => {
			window.location.reload(false);
		}, 2000);
	};

	return (
		<Card
			style={{
				padding: "1rem",
				marginBottom: "1rem",
				background: "#f3fdff",
			}}
		>
			<Grid container spacing={2}>
				<Grid item md={6}>
					<Stack
						direction="row"
						spacing={1}
						alignItems="center"
						height="100%"
						style={{ marginLeft: ".5rem" }}
					>
						<Avatar
							sx={{ width: 24, height: 24 }}
							src={requester && requester?.photoUrl}
						/>
						<Typography>{requester?.displayName}</Typography>{" "}
						<b> is Joining</b>
						<Avatar
							sx={{ width: 24, height: 24 }}
							src={recipient && recipient?.photoUrl}
						/>
						<Typography style={{ marginLeft: ".5rem" }}>
							{recipient?.displayName}
						</Typography>
					</Stack>
				</Grid>
				<Grid item md={6} style={{ textAlign: "right" }}>
					<Button
						style={{
							margin: "1rem",
						}}
						color="primary"
						onClick={handleReject}
					>
						Reject
					</Button>
					<Button
						onClick={handleApproval}
						margin={2}
						disabled={loading}
						color="primary"
						variant="contained"
					>
						Approval
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
};

export default UserFollowList;
