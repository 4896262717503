import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
	updatePassword,
} from "firebase/auth";
import { toast } from "react-toastify";
import {
	doc,
	setDoc,
	getDoc,
	updateDoc,
	serverTimestamp,
} from "firebase/firestore";
import CryptoJS from "crypto-js";

import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	CLEAR_PROFILE,
	GET_AUTH_PROFILE,
} from "./actionTypes";
import { auth, db } from "../../firebase-config";
import { VerifyErroCode } from "../../utils/firebaseError";

const secretKey = "NetworkBM2022!@#";

export const resetPassword = async (userEmail, userPassword, newPassword) => {
	try {
		// Decrypt
		const bytes = CryptoJS.AES.decrypt(userPassword, secretKey);
		const originalPwd = bytes.toString(CryptoJS.enc.Utf8);
		const { user } = await signInWithEmailAndPassword(
			auth,
			userEmail,
			originalPwd
		);
		// Encrypt hash password again
		const hashedPassword = CryptoJS.AES.encrypt(
			newPassword,
			secretKey
		).toString();

		await updatePassword(user, newPassword);
		const userRef = doc(db, "users", user?.uid);
		await updateDoc(userRef, {
			password: hashedPassword,
		});
		toast.success("Updated Password Successfully!");
	} catch (error) {
		console.log("Here is error message: reset Password", error);
	}
};

export const getAuthUserProfile = () => async (dispatch) => {
	try {
		onAuthStateChanged(auth, async (currentUser) => {
			if (currentUser) {
				const userSnapshot = await getDoc(
					doc(db, "users", currentUser.uid)
				);
				const user = userSnapshot.data();

				if (user.role !== "admin") {
					toast.warn("You can not access, Pls try again later!");
					dispatch({ type: LOGOUT });
				} else {
					dispatch({
						type: GET_AUTH_PROFILE,
						payload: { ...currentUser, ...user },
					});
				}
			} else {
				console.log("here is error get auth profile");
			}
		});
	} catch (err) {
		const errorCode = err.code;
		let errorMessage = VerifyErroCode(errorCode);

		if (errorMessage == null) {
			errorMessage = err.message;
		}
	}
};

// Load User
export const loadUser = () => async (dispatch) => {
	try {
		onAuthStateChanged(auth, async (currentUser) => {
			if (currentUser) {
				const userSnapshot = await getDoc(
					doc(db, "users", currentUser?.uid)
				);

				if (userSnapshot.exists()) {
					dispatch({
						type: USER_LOADED,
						payload: currentUser,
					});
				} else {
					dispatch({
						type: AUTH_ERROR,
					});
				}
			}
		});
	} catch (err) {
		const errorCode = err.code;
		let errorMessage = VerifyErroCode(errorCode);

		if (errorMessage == null) {
			errorMessage = err.message;
		}
		toast.error(`${errorMessage}`);
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

// Register User
export const register =
	({ firstName, lastName, email, password, role }) =>
	async (dispatch) => {
		try {
			const res = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const { uid } = res.user;
			// check if user already existed or not
			const noteSnapshot = await getDoc(doc(db, "users", uid));
			if (!noteSnapshot.exists()) {
				// create new user in database
				await setDoc(doc(db, "users", uid), {
					uid,
					displayName: `${firstName} ${lastName}`,
					firstName,
					lastName,
					email,
					password,
					role,
					joinedDate: serverTimestamp(),
				});
			}
			dispatch({
				type: REGISTER_SUCCESS,
				payload: res,
			});
			dispatch(loadUser(res));
		} catch (err) {
			const errorCode = err.code;
			let errorMessage = VerifyErroCode(errorCode);

			if (errorMessage == null) {
				errorMessage = err.message;
			}
			toast.error(`${errorMessage}`);

			dispatch({
				type: REGISTER_FAIL,
			});
		}
	};

// Login User
export const login = (email, password) => async (dispatch) => {
	try {
		const res = await signInWithEmailAndPassword(auth, email, password);
		const { uid } = res.user;
		const noteSnapshot = await getDoc(doc(db, "users", uid));

		if (noteSnapshot.exists()) {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: res,
			});

			dispatch(loadUser(res));
			dispatch(getAuthUserProfile(uid));
		} else {
			toast.error("User not exist!");
		}
	} catch (err) {
		const errorCode = err.code;
		let errorMessage = VerifyErroCode(errorCode);

		if (errorMessage == null) {
			errorMessage = err.message;
		}
		toast.error(`${errorMessage}`);
		dispatch({
			type: LOGIN_FAIL,
		});
	}
};

// Logout

export const logout = () => async (dispatch) => {
	try {
		dispatch({ type: LOGOUT });
		dispatch({ type: CLEAR_PROFILE });
		await signOut(auth);
	} catch (err) {
		const errorCode = err.code;
		let errorMessage = VerifyErroCode(errorCode);

		if (errorMessage == null) {
			errorMessage = err.message;
		}
		toast.error(`${errorMessage}`);
		console.log(err);
	}
};
