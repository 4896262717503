import React, { useState } from "react";
import {
	Box,
	CardContent,
	Grid,
	Modal,
	Typography,
	TextField,
	InputAdornment,
	IconButton,
	Button,
} from "@mui/material";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useFormik, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { resetPassword } from "../../redux/actions/auth";

const ResetPasswordModal = ({ open, handleClose, profile }) => {
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const ResetPasswordSchema = Yup.object().shape({
		/* eslint-disable */
		password: Yup.string()
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
			)
			.required("Password is required!"),
	});

	const handleChange = (e) => {
		setPassword(e.target.value);
	};

	const handleSubmit = async () => {
		await resetPassword(
			profile?.email,
			profile?.password,
			values?.password
		);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		border: "1px solid #eee",
		boxShadow: 24,
		p: 4,
	};
	const formik = useFormik({
		initialValues: {
			password: "",
		},
		validationSchema: ResetPasswordSchema,
	});
	const { errors, touched, getFieldProps, isValid, isSubmitting, values } =
		formik;

	return (
		<FormikProvider value={formik}>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						Update {profile?.displayName}'s Password
					</Typography>
					<CardContent>
						<Grid>
							<Grid item>
								<TextField
									fullWidth
									type={showPassword ? "text" : "password"}
									label="New Password"
									name="password"
									onChange={handleChange}
									required
									{...getFieldProps("password")}
									value={values?.password}
									variant="outlined"
									error={Boolean(
										touched.password && errors.password
									)}
									helperText={
										touched.password && errors.password
									}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													edge="end"
													onClick={() =>
														setShowPassword(
															(prev) => !prev
														)
													}
												>
													<Icon
														icon={
															showPassword
																? eyeFill
																: eyeOffFill
														}
													/>
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>

						<Box
							sx={{
								paddingTop: 2,
							}}
						>
							<Button
								loading={isSubmitting}
								onClick={() => handleSubmit()}
								color="primary"
								variant="contained"
								disabled={!isValid || values?.password === ""}
							>
								Update
							</Button>
						</Box>
					</CardContent>
				</Box>
			</Modal>
		</FormikProvider>
	);
};

export default ResetPasswordModal;
