import {
	doc,
	getDoc,
	updateDoc,
	setDoc,
	serverTimestamp,
	deleteDoc,
} from "firebase/firestore";

import { db } from "../../firebase-config";

export const fetchResourceInfo = async (id) => {
	try {
		const userSnapshot = await getDoc(doc(db, "resource", id));
		if (userSnapshot.exists()) {
			console.log("userSnapshot.data():::", userSnapshot.data());
			return userSnapshot.data();
		}
	} catch (error) {
		console.log("her eis errro:", error);
	}
};
export const setResource = async (id, { items }) => {
	try {
		await setDoc(doc(db, "resource", id), {
			items,
			createdDate: serverTimestamp(),
		});
	} catch (error) {
		console.log("here is error:", error);
	}
};
// Register User

// update resource resource
export const updateResource = async (id, { items }) => {
	try {
		await updateDoc(doc(db, "resource", id), {
			items,
		});
	} catch (error) {
		console.log("here is error:", error);
	}
};

export const deleteUser = (id) => async (dispatch) => {
	try {
		const userRef = doc(db, "users", id);
		await deleteDoc(userRef);
	} catch (error) {
		console.log("here is errro:", error);
	}
};
