import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// import { formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
// material
import { alpha } from "@mui/material/styles";
import {
	Box,
	List,
	Badge,
	Button,
	Tooltip,
	Divider,
	IconButton,
	Typography,
	ListItemText,
	ListSubheader,
	ListItemButton,
} from "@mui/material";
// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import {
	getAllNotification,
	updateAdminReadNoti,
	getUnreadNotification,
} from "../../redux/actions/notificaiton";

function renderContent(notification) {
	const content = (
		<Typography variant="subtitle2">
			{notification && notification?.content}
			<Typography
				component="span"
				variant="body2"
				sx={{ color: "text.secondary" }}
			>
				{/* &nbsp; {noCase(notification.description)} */}
			</Typography>
		</Typography>
	);

	return {
		// avatar: <img alt={notification?.content} src={notification.avatar} />,
		title: content,
	};
}

NotificationItem.propTypes = {
	notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification, handleClose }) {
	const { title } = renderContent(notification);
	const handleUpdateRead = async (notificaiton) => {
		await updateAdminReadNoti(notificaiton?.id);
	};
	return (
		<ListItemButton
			to={`/dashboard/account/${notification.userId.substring(
				0,
				notification.userId.indexOf("-")
			)}`}
			onClick={() => handleClose()}
			disableGutters
			component={RouterLink}
			sx={{
				py: 1.5,
				px: 2.5,
				mt: "1px",
				...(notification?.read && {
					bgcolor: "action.selected",
				}),
			}}
		>
			<ListItemText
				primary={title}
				onClick={() => handleUpdateRead(notification)}
				secondary={
					<Typography
						variant="caption"
						sx={{
							mt: 0.5,
							display: "flex",
							alignItems: "center",
							color: "text.disabled",
						}}
					>
						<Box
							component={Icon}
							icon={clockFill}
							sx={{ mr: 0.5, width: 16, height: 16 }}
						/>

						{notification.createdDate?.toDate().toDateString()}
						{"  "}
						{
							notification.createdDate
								?.toDate()
								?.toTimeString()
								?.split(" ")[0]
						}
						{/* {formatDistanceToNow(
							notification.createdDate?.toDate()
						)} */}
					</Typography>
				}
			/>
		</ListItemButton>
	);
}

export default function NotificationsPopover() {
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const totalUnRead = notifications?.filter(
		(item) => item?.admin_read === false
	).length;

	useEffect(() => {
		const _fetchNoti = async () => {
			const _data = await getUnreadNotification();
			setNotifications(_data);
		};
		_fetchNoti();
		// eslint-disable-next-line
	}, []);
	const fetchNotification = async () => {
		const _dataNew = await getUnreadNotification();
		setNotifications(_dataNew);
	};
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleMarkAllAsRead = () => {
		setNotifications(
			notifications.map((notification) => ({
				...notification,
				isUnRead: false,
			}))
		);
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				size="large"
				color={open ? "primary" : "default"}
				onClick={handleOpen}
				sx={{
					...(open && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.focusOpacity
							),
					}),
				}}
			>
				<Badge badgeContent={totalUnRead} color="error">
					<Icon icon={bellFill} width={20} height={20} />
				</Badge>
			</IconButton>

			<MenuPopover
				open={open}
				onClose={handleClose}
				anchorEl={anchorRef.current}
				sx={{ width: 360 }}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						py: 2,
						px: 2.5,
					}}
				>
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle1">
							Notifications
						</Typography>
					</Box>

					{totalUnRead > 0 && (
						<Tooltip title=" Mark all as read">
							<IconButton
								color="primary"
								onClick={handleMarkAllAsRead}
							>
								<Icon
									icon={doneAllFill}
									width={20}
									height={20}
								/>
							</IconButton>
						</Tooltip>
					)}

					<Button
						onClick={() => fetchNotification()}
						style={{
							marginLeft: "auto",
						}}
						color="success"
						variant="text"
					>
						Check
					</Button>
				</Box>

				<Divider />

				<Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
					<List
						disablePadding
						subheader={
							<ListSubheader
								disableSticky
								sx={{ py: 1, px: 2.5, typography: "overline" }}
							>
								<Button
									disableRipple
									component={RouterLink}
									to="#"
									disabled
								>
									Unread
								</Button>
								<Button
									disableRipple
									component={RouterLink}
									sx={{
										cursor: "pointer",
									}}
									to="/dashboard/pending-notification"
								>
									View Pending
								</Button>

								<Button
									disableRipple
									component={RouterLink}
									sx={{
										cursor: "pointer",
									}}
									to="/dashboard/notification"
								>
									All
								</Button>
							</ListSubheader>
						}
					>
						{notifications?.map((notification, index) => (
							<NotificationItem
								key={index}
								notification={notification}
								handleClose={handleClose}
							/>
						))}
					</List>

					{/* <List
						disablePadding
						subheader={
							<ListSubheader
								disableSticky
								sx={{ py: 1, px: 2.5, typography: "overline" }}
							>
								Before that
							</ListSubheader>
						}
					>
						{notifications.slice(2, 5).map((notification) => (
							<NotificationItem
								key={notification.id}
								notification={notification}
							/>
						))}
					</List> */}
				</Scrollbar>

				<Divider />

				{/* <Box sx={{ p: 1 }}>
					<Button
						fullWidth
						disableRipple
						component={RouterLink}
						to="/notification-pending"
					>
						View Pending
					</Button>
				</Box> */}
			</MenuPopover>
		</>
	);
}
