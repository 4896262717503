import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Typography,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
	ref,
	uploadBytesResumable,
	getDownloadURL,
	getStorage,
} from "firebase/storage";
import { db } from "../../firebase-config";
import { approvalUser } from "../../redux/actions/user";
import ResetPasswordModal from "./ResetPasswordModal";
import { AccountProfileDetails } from "./AccountDetail";

const AccountProfile = ({ profile, id, updateUserProfile }) => {
	const inputRef = useRef(null);
	const [avatarUrl, setAvatarUrl] = useState(" ");
	const [message, setMessage] = useState("");
	const [isUpload, setUpload] = useState(false);
	const [isOpenProfile, setIsOpenProfile] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleResetPassword = () => {
		setIsOpen(true);
	};

	useEffect(() => {
		setAvatarUrl(profile?.photoUrl);
		// eslint-disable-next-line
	}, [profile?.photoUrl]);

	useEffect(() => {
		const fetchData = async () => {
			if (avatarUrl !== " " && isUpload) {
				const userRef = doc(db, "users", id);
				await updateDoc(userRef, {
					photoUrl: avatarUrl,
				});
			}
		};
		fetchData();
		// eslint-disable-next-line
	}, [isUpload]);

	const handleUploadFile = (event) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			// poImg.current = e.target.result;
		};
		const file = event.target.files[0];
		reader.readAsDataURL(file);
		setMessage("Uploading...");
		const storage = getStorage();
		const storageRef = ref(storage, `profiles/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file, "data_url");
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setMessage(`Upload is  ${progress}% done`);
				toast.success(`Upload is ${progress}% done`);
			},
			(error) => {
				// Handle unsuccessful uploads
				throw error;
			},
			() => {
				// Handle successful uploads on complete
				// For instance, get the download URL: https://firebasestorage.googleapis.com/...
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setAvatarUrl(downloadURL);
					setUpload(true);
					toast.success(`Upload Image Successfully!`);
				});
			}
		);
	};

	const handleApprovalUser = async () => {
		await approvalUser(profile?.uid);
		toast.success("Approval Successfully!!");
		setTimeout(() => {
			window.location.reload();
		}, 1200);
	};

	return (
		<div>
			<Card>
				<CardContent>
					<Box
						sx={{
							alignItems: "center",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Avatar
							src={avatarUrl}
							sx={{
								height: 64,
								mb: 2,
								width: 64,
							}}
						/>
						<Typography
							color="textPrimary"
							gutterBottom
							variant="h5"
						>
							{profile?.displayName}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							{`${profile?.role}`}
						</Typography>
					</Box>
				</CardContent>
				<Divider />
				<CardActions>
					<Button
						onClick={() => {
							inputRef.current.value = "";

							inputRef.current.click();
						}}
						color="primary"
						fullWidth
						variant="text"
					>
						Upload picture
					</Button>
				</CardActions>

				{!profile?.approval && (
					<CardActions>
						<Button
							color="primary"
							variant="contained"
							sx={{
								margin: "auto",
							}}
							onClick={handleApprovalUser}
						>
							Approval User
						</Button>
					</CardActions>
				)}
				<input
					onChange={handleUploadFile}
					type="file"
					id="my_photo"
					accept="image/*"
					ref={inputRef}
					style={{
						display: "none",
					}}
				/>

				{profile?.approval && (
					<CardActions style={{ marginBottom: "0rem" }}>
						<Button
							color="info"
							fullWidth
							variant="text"
							onClick={handleResetPassword}
						>
							Reset Password
						</Button>
					</CardActions>
				)}
				<CardActions style={{ marginBottom: ".5rem" }}>
					<Button
						color="info"
						fullWidth
						variant="text"
						onClick={() => setIsOpenProfile(true)}
					>
						Profile
					</Button>
				</CardActions>
			</Card>
			<ResetPasswordModal
				profile={profile}
				open={isOpen}
				handleClose={() => setIsOpen(false)}
			/>
			<AccountProfileDetails
				id={id}
				profile={profile}
				open={isOpenProfile}
				handleClose={() => setIsOpenProfile(false)}
				updateUserProfile={updateUserProfile}
			/>
		</div>
	);
};

export default AccountProfile;
