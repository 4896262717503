import React, { useEffect, useState } from "react";
import {
	Divider,
	Button,
	Container,
	TextField,
	CardHeader,
} from "@mui/material";
import { toast } from "react-toastify";
import { FieldArray, Form, Formik, getIn } from "formik";
import DeleteIcon from "@iconify/icons-ant-design/delete-fill";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import {
	updateResource,
	fetchResourceInfo,
} from "../../redux/actions/resource";

const validationSchema = Yup.object().shape({
	items: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required("Resource Name is required"),
			link_url: Yup.string().required("Url Link is required"),
			type: Yup.string(),
		})
	),
});

export const SettingResourceInfo = () => {
	const [rState, setResourceState] = useState([
		{
			id: Math.random(),
			name: "",
			link_url: "",
			type: "A",
		},
	]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let mounted = true;
		const fetchResource = async () => {
			const data = await fetchResourceInfo("items-setting-v1");

			if (mounted) {
				setResourceState(data?.items);
				setLoading(false);
			}
		};
		fetchResource();
		return () => (mounted = false);
	}, []);

	const options = [
		{
			value: "A",
			label: "A",
		},
		{
			value: "B",
			label: "B",
		},
		{
			value: "C",
			label: "C",
		},
		{
			value: "D",
			label: "D",
		},
		{
			value: "E",
			label: "E",
		},
		{
			value: "F",
			label: "F",
		},
		{
			value: "G",
			label: "G",
		},
		{
			value: "H",
			label: "H",
		},
		{
			value: "I",
			label: "I",
		},
		{
			value: "J",
			label: "J",
		},
		{
			value: "K",
			label: "K",
		},
		{
			value: "M",
			label: "M",
		},
		{
			value: "N",
			label: "N",
		},
		{
			value: "L",
			label: "L",
		},
		{
			value: "O",
			label: "O",
		},
		{
			value: "P",
			label: "P",
		},
		{
			value: "Q",
			label: "Q",
		},
		{
			value: "R",
			label: "R",
		},
		{
			value: "S",
			label: "S",
		},
		{
			value: "T",
			label: "T",
		},
		{
			value: "U",
			label: "U",
		},
		{
			value: "V",
			label: "V",
		},
		{
			value: "W",
			label: "W",
		},
		{
			value: "X",
			label: "X",
		},
		{
			value: "Y",
			label: "Y",
		},
		{
			value: "Z",
			label: "Z",
		},
	];
	if (loading) {
		return <div>Loading...</div>;
	}
	return (
		<Container>
			<CardHeader
				subheader="Resources will show at www.ssbu.edu.mm/e-resources"
				title="SSBU E-Resource Information"
			/>
			<Divider style={{ marginTop: 20, marginBottom: 20 }} />
			<Formik
				initialValues={{
					items: rState,
				}}
				validationSchema={validationSchema}
				onSubmit={async (values) => {
					await updateResource("items-setting-v1", values);
					toast.success("Added Resource Successfully!");
				}}
			>
				{({
					values,
					touched,
					errors,
					isSubmitting,
					handleChange,
					handleBlur,
					isValid,
				}) => (
					<Form noValidate autoComplete="off">
						<FieldArray name="items">
							{({ push, remove }) => (
								<div>
									{values?.items?.map((p, index) => {
										const rName = `items[${index}].name`;

										const touchedFirstName = getIn(
											touched,
											rName
										);
										const errorFirstName = getIn(
											errors,
											rName
										);
										const itemOption = `items[${index}].type`;
										const touchedCountry = getIn(
											touched,
											itemOption
										);
										const errorCountry = getIn(
											errors,
											itemOption
										);
										const itemLink = `items[${index}].link_url`;
										const touchedLastName = getIn(
											touched,
											itemLink
										);
										const errorLastName = getIn(
											errors,
											itemLink
										);

										return (
											<div key={p.id}>
												<TextField
													margin="normal"
													variant="outlined"
													label="Resource Name"
													name={rName}
													value={p.name}
													required
													helperText={
														touchedFirstName &&
														errorFirstName
															? errorFirstName
															: ""
													}
													style={{
														marginTop: 20,
														marginRight: 20,
													}}
													error={Boolean(
														touchedFirstName &&
															errorFirstName
													)}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<TextField
													margin="normal"
													variant="outlined"
													label="Link URL"
													name={itemLink}
													value={p.link_url}
													required
													style={{
														marginTop: 20,
														marginRight: 20,
													}}
													helperText={
														touchedLastName &&
														errorLastName
															? errorLastName
															: ""
													}
													error={Boolean(
														touchedLastName &&
															errorLastName
													)}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<TextField
													label="Select A - Z "
													name={itemOption}
													onChange={handleChange}
													select
													value={p.type}
													helperText={
														touchedCountry &&
														errorCountry
															? errorCountry
															: ""
													}
													style={{
														width: 120,
														marginTop: 20,
														marginRight: 20,
													}}
													SelectProps={{
														native: true,
													}}
													variant="outlined"
												>
													{options?.map((option) => (
														<option
															key={option.value}
															value={option.value}
														>
															{option.label}
														</option>
													))}
												</TextField>
												<div
													style={{
														display: "inline-block",
														marginTop: "1.8rem",
													}}
												>
													<Button
														margin="normal"
														type="button"
														color="secondary"
														variant="outlined"
														onClick={() =>
															remove(index)
														}
													>
														<Icon
															icon={DeleteIcon}
															width={20}
															height={20}
														/>
													</Button>
												</div>
											</div>
										);
									})}
									<Button
										type="button"
										variant="outlined"
										onClick={() =>
											push({
												id: Math.random(),
												name: "",
												link_url: "",
												type: "A",
											})
										}
									>
										Add
									</Button>
								</div>
							)}
						</FieldArray>
						<Divider style={{ marginTop: 20, marginBottom: 20 }} />
						<Button
							type="submit"
							color="primary"
							disabled={isSubmitting}
							variant="contained"
						>
							Save
						</Button>
						<Divider style={{ marginTop: 20, marginBottom: 20 }} />
					</Form>
				)}
			</Formik>
		</Container>
	);
};
