import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import clockFill from "@iconify/icons-eva/clock-fill";
import {
	Box,
	Typography,
	ListItemText,
	ListItemButton,
	Button,
} from "@mui/material";
import { toast } from "react-toastify";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import {
	getAllNotification,
	deleteNotification,
} from "../../redux/actions/notificaiton";

function renderContent(notification) {
	const content = (
		<Typography variant="subtitle2">
			{notification && notification?.content}
			<Typography
				component="span"
				variant="body2"
				sx={{ color: "text.secondary" }}
			>
				{/* &nbsp; {noCase(notification.description)} */}
			</Typography>
		</Typography>
	);

	return {
		title: content,
	};
}

NotificationItem.propTypes = {
	notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }) {
	const { title } = renderContent(notification);
	const handleUpdateRead = () => {};

	const handleDelete = async (notification) => {
		console.log("here is notfi", notification);
		await deleteNotification(notification?.id);
		toast.success("Delete Successfully!");
		await getAllNotification();
	};
	return (
		<>
			<ListItemButton
				to={`/dashboard/account/${notification.userId.substring(
					0,
					notification.userId.indexOf("-")
				)}`}
				disableGutters
				component={RouterLink}
				sx={{
					py: 1.5,
					px: 2.5,
					mt: "1px",
					...(notification?.read && {
						bgcolor: "action.selected",
					}),
				}}
			>
				<ListItemText
					primary={title}
					onClick={() => handleUpdateRead(notification)}
					secondary={
						<Typography
							variant="caption"
							sx={{
								mt: 0.5,
								display: "flex",
								alignItems: "center",
								color: "text.disabled",
							}}
						>
							<Box
								component={Icon}
								icon={clockFill}
								sx={{ mr: 0.5, width: 16, height: 16 }}
							/>

							{notification.createdDate?.toDate().toDateString()}
							{"  "}
							{
								notification.createdDate
									?.toDate()
									?.toTimeString()
									?.split(" ")[0]
							}
						</Typography>
					}
				/>
			</ListItemButton>
			{/* <div
				style={{
					display: "flex",
				}}
			>
				<Button
					onClick={() => handleDelete(notification)}
					style={{
						marginLeft: "auto",
					}}
					color="error"
					variant="text"
				>
					<Icon icon={trash2Outline} width={20} height={20} /> Delete
				</Button>
			</div> */}
		</>
	);
}

const PendingNotificaiton = () => {
	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		const _fetchData = async () => {
			const data = await getAllNotification();
			setNotifications(data);
		};
		_fetchData();
	}, []);

	return (
		<Box
			sx={{
				py: 2,
				px: 2.5,
			}}
		>
			<Typography variant="subtitle1">All Notifications</Typography>
			{notifications?.map((notification) => (
				<NotificationItem
					key={notification.id}
					notification={notification}
				/>
			))}
		</Box>
	);
};

export default PendingNotificaiton;
