import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import { toast } from "react-toastify";

// material
import {
	Card,
	Table,
	Stack,
	Avatar,
	Button,
	Checkbox,
	Box,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
	Modal,
} from "@mui/material";

import { connect } from "react-redux";
import { getUsers, _deleteUser } from "../redux/actions/user";

// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../components/_dashboard/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	{ id: "displayName", label: "Name", alignRight: false },
	{ id: "role", label: "Role", alignRight: false },
	{ id: "email", label: "Email", alignRight: false },
	{ id: "approval", label: "Approval", alignRight: false },
	{ id: "member", label: "Members", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(
			array,
			(_user) =>
				_user.displayName.toLowerCase().indexOf(query.toLowerCase()) !==
				-1
		);
	}
	return stabilizedThis?.map((el) => el[0]);
}

const User = ({ loading, users, getUsers, _deleteUser }) => {
	const [page, setPage] = useState(0);
	const [showDelete, setShowDelete] = useState(false);
	const [userInfo, setUserInfo] = useState({});
	const [order, setOrder] = useState("asc");
	const [selected, setSelected] = useState([]);
	const [orderBy, setOrderBy] = useState("name");
	const [filterName, setFilterName] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(5);

	useEffect(async () => {
		await getUsers();
		// eslint-disable-next-line
	}, []);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = users.map((n) => n.displayName);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

	const filteredUsers = applySortFilter(
		users,
		getComparator(order, orderBy),
		filterName
	);

	const handleDelete = async ({ uid, displayName }) => {
		setShowDelete(true);
		setUserInfo({
			uid,
			displayName,
		});
	};

	const linkStyle = {
		textDecoration: "none",
		color: "inherit",
		cursor: "pointer",
	};
	const CustomLink = ({ children, id }) => (
		<Stack
			style={linkStyle}
			direction="row"
			alignItems="center"
			component={Link}
			to={`/dashboard/account/${id}`}
		>
			<Typography variant="subtitle2" noWrap>
				{children}
			</Typography>
		</Stack>
	);

	const isUserNotFound = filteredUsers?.length === 0;

	if (loading || users?.length < 0) {
		return <div>loading ...</div>;
	}
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 350,
		margin: "1rem",
		bgcolor: "background.paper",
		border: "1px solid #eee",
		boxShadow: 24,
		p: 4,
	};
	return (
		<Page title="User | SSBU">
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						User
					</Typography>
				</Stack>

				<Card>
					<UserListToolbar
						numSelected={selected?.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/>

					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table>
								<UserListHead
									order={order}
									orderBy={orderBy}
									headLabel={TABLE_HEAD}
									rowCount={users?.length}
									numSelected={selected?.length}
									onRequestSort={handleRequestSort}
									onSelectAllClick={handleSelectAllClick}
								/>
								<TableBody>
									{filteredUsers
										?.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage
										)
										?.map((row) => {
											const {
												uid,
												displayName,
												role,
												email,
												photoUrl,
												members,
												approval,
											} = row;
											const isItemSelected =
												selected?.indexOf(
													displayName
												) !== -1;

											return (
												<TableRow
													hover
													key={uid}
													tabIndex={-1}
													role="checkbox"
													selected={isItemSelected}
													aria-checked={
														isItemSelected
													}
												>
													<TableCell padding="checkbox">
														<Checkbox
															checked={
																isItemSelected
															}
															onChange={(event) =>
																handleClick(
																	event,
																	displayName
																)
															}
														/>
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														padding="none"
													>
														<Stack
															style={linkStyle}
															direction="row"
															alignItems="center"
															spacing={2}
															component={Link}
															to={`/dashboard/account/${uid}`}
														>
															<Avatar
																alt={
																	displayName
																}
																src={photoUrl}
															/>
															<Typography
																variant="subtitle2"
																noWrap
															>
																{displayName}
															</Typography>
														</Stack>
													</TableCell>

													<TableCell align="left">
														<CustomLink id={uid}>
															{role}
														</CustomLink>
													</TableCell>
													<TableCell align="left">
														<CustomLink id={uid}>
															{email}
														</CustomLink>
													</TableCell>
													<TableCell align="left">
														<CustomLink id={uid}>
															{approval
																? "Yes"
																: "No"}
														</CustomLink>
													</TableCell>
													<TableCell align="left">
														<CustomLink id={uid}>
															{members || 0}
														</CustomLink>
													</TableCell>
													<TableCell align="right">
														<Button
															onClick={() =>
																handleDelete(
																	row
																)
															}
															color="error"
															variant="text"
														>
															<Icon
																icon={
																	trash2Outline
																}
																width={20}
																height={20}
															/>{" "}
															Delete
														</Button>
													</TableCell>
												</TableRow>
											);
										})}
									{emptyRows > 0 && (
										<TableRow
											style={{ height: 53 * emptyRows }}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
								{isUserNotFound && (
									<TableBody>
										<TableRow>
											<TableCell
												align="center"
												colSpan={6}
												sx={{ py: 3 }}
											>
												<SearchNotFound
													searchQuery={filterName}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								)}
							</Table>
						</TableContainer>
					</Scrollbar>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={users?.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
					<Modal
						open={showDelete}
						onClose={() => {
							setShowDelete(false);
						}}
					>
						<Box sx={style}>
							<Typography>
								Are you sure you want to delete{" "}
								<b>{userInfo?.displayName}</b>?
							</Typography>
							<Button
								onClick={() => {
									setShowDelete(false);
								}}
								color="error"
								variant="text"
							>
								No
							</Button>
							<Button
								onClick={async () => {
									await _deleteUser(userInfo?.uid);
									setShowDelete(false);
									toast.success("Delete User Successfully!");
								}}
								color="primary"
								variant="text"
							>
								Yes
							</Button>
						</Box>
					</Modal>
				</Card>
			</Container>
		</Page>
	);
};

const mapStateToProps = (state) => ({
	users: state.user.users,
	loading: state.user.loading,
});

export default connect(mapStateToProps, { getUsers, _deleteUser })(User);
