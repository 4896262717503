import { useState, useEffect } from "react";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
	Modal,
} from "@mui/material";

import { toast } from "react-toastify";

const states = [
	{
		value: "agent",
		label: "Agent",
	},
	{
		value: "member",
		label: "Member",
	},
	{
		value: "admin",
		label: "Admin",
	},
];
const countries = [
	{
		value: "Myanmar",
		label: "Myanmar",
	},
	{
		value: "Thailand",
		label: "Thailand",
	},
];

export const AccountProfileDetails = ({
	open,
	handleClose,
	id,
	profile,
	updateUserProfile,
}) => {
	const [values, setValues] = useState({
		firstName: "",
		lastName: "",
		email: "",
		role: "",
		country: "Myanmar",
		phone: "",
		cardHolder: "",
		creditCard: "",
		bankName: "",
		isAgent: false,
		amount: "",
	});

	useEffect(() => {
		setValues({
			firstName: profile?.firstName,
			lastName: profile?.lastName,
			email: profile?.email,
			role: profile?.role,
			country: profile?.country,
			phone: profile?.phone,
			cardHolder: profile?.cardHolder,
			creditCard: profile?.creditCard,
			bankName: profile?.bankName,
			isAgent: profile?.isAgent,
			amount: profile?.amount,
		});
	}, [profile]);

	const handleChange = (event) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};

	const handleSave = async (e) => {
		await updateUserProfile({
			id,
			...values,
		});

		toast.success("Profile Updated Successfully!!");
		handleClose();
	};

	return (
		<Modal
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			open={open}
			onClose={handleClose}
			onTouch={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<form autoComplete="off" noValidate>
				<Card
					style={{
						width: "85%",
						margin: "auto",
						// maxHeight: '38rem',
						position: "absolute",
						top: 30,
						left: 0,
						bottom: 0,
						right: 0,
						zIndex: 99999999,
						overflow: "scroll",
					}}
					onTouch={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<CardHeader
						subheader="The information can be edited"
						title="Profile"
					/>
					<Divider />
					<CardContent>
						<Grid container spacing={3}>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									helperText="Please specify the first name"
									label="First name"
									name="firstName"
									onChange={handleChange}
									required
									value={values.firstName || ""}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Last name"
									name="lastName"
									onChange={handleChange}
									required
									value={values.lastName || ""}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Select Role"
									name="role"
									onChange={handleChange}
									required
									select
									SelectProps={{ native: true }}
									value={values.role || ""}
									variant="outlined"
								>
									{states.map((option) => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Email Address"
									name="email"
									onChange={handleChange}
									disabled
									// required
									value={values.email || ""}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Phone Number"
									name="phone"
									onChange={handleChange}
									type="text"
									value={values.phone || ""}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Select Country"
									name="country"
									onChange={handleChange}
									required
									select
									SelectProps={{ native: true }}
									value={values.country || ""}
									variant="outlined"
								>
									{countries.map((option) => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Card Holder"
									name="cardHolder"
									onChange={handleChange}
									type="text"
									value={values?.cardHolder || ""}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Credit Card"
									name="creditCard"
									onChange={handleChange}
									type="text"
									value={values.creditCard || ""}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Bank Name"
									name="bankName"
									onChange={handleChange}
									type="text"
									value={values.bankName || ""}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Amount"
									name="amount"
									onChange={handleChange}
									type="text"
									value={values.amount}
									variant="outlined"
								/>
							</Grid>
							{/* <Grid item md={6} xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										name="isAgent"
										checked={values?.isAgent || false}
										onChange={(e) => {
											handleChecked(e);
										}}
									/>
								}
								label="Is Agent"
							/>
						</Grid> */}
						</Grid>
					</CardContent>
					<Divider />

					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							p: 2,
						}}
					>
						<Button
							onClick={() => handleClose()}
							color="error"
							variant="text"
							style={{
								margin: "2px 1rem",
							}}
						>
							Close
						</Button>
						<Button
							onClick={handleSave}
							color="primary"
							variant="contained"
						>
							Save details
						</Button>
					</Box>
				</Card>
			</form>
		</Modal>
	);
};
