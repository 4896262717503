export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_FOLLOW = "GET_FOLLOW";
export const GET_FOLLOW_COUNT = "GET_FOLLOW_COUNT";
export const GET_PROFILES = "GET_PROFILES";
export const GET_AUTH_PROFILE = "GET_AUTH_PROFILE";
export const GET_USERS = "GET_USERS";
export const GET_REPOS = "GET_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFIL_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
// @react-intl
export const SET_LOCALE = "SET_LOCALE";
export const GET_LOCALE = "GET_LOCALE";
// @get and set topic id
export const GET_TOPIC_ID = "GET_TOPIC_ID";
export const SET_TOPIC_ID = "SET_TOPIC_ID";

// get slug
export const GET_SLUG = "GET_SLUG";
// set Meta
export const SET_META = "SET_META";

// toggles
export const TOGGLE_MODEL = "TOGGLE_MODEL";

// notification
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
