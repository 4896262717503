// material
import { Container, Box } from "@mui/material";
// import { SettingsNotifications } from "../components/settings/settingsNotifications";
import { SettingResourceInfo } from "../components/settings/settingsResourceInfo";
// components
import Page from "../components/Page";

// ----------------------------------------------------------------------

export default function Settings() {
	return (
		<Page title="Dashboard: SSBU Library">
			<Container>
				<Box sx={{ pt: 3 }}>
					<SettingResourceInfo />
				</Box>
			</Container>
		</Page>
	);
}
