import { Navigate } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import User from "./pages/User";
import Account from "./pages/Account";
import CreateUser from "./pages/CreateUser";
import NotFound from "./pages/Page404";
import Resource from "./pages/Resource";
import PendingNotificaiton from "./layouts/dashboard/PendingNotificaiton";
import AllNotification from "./layouts/dashboard/AllNotification";

// ----------------------------------------------------------------------

const routers = (isAuthenticated) => [
	{
		path: "/dashboard",
		element: isAuthenticated ? (
			<DashboardLayout />
		) : (
			<Navigate to="/login" />
		),
		children: [
			{ element: <Navigate to="/dashboard/app" replace /> },
			{ path: "app", element: <DashboardApp /> },
			{ path: "user", element: <User /> },
			{ path: "account/:id", element: <Account /> },
			{ path: "user/create", element: <CreateUser /> },
			{ path: "pending-notification", element: <PendingNotificaiton /> },
			{ path: "notification", element: <AllNotification /> },
			{ path: "resource", element: <Resource /> },
		],
	},
	{
		path: "/",
		element: <LogoOnlyLayout />,
		children: [
			{ path: "login", element: <Login /> },
			{ path: "register", element: <Register /> },
			{ path: "404", element: <NotFound /> },
			{ path: "/", element: <Navigate to="/dashboard" /> },
			{ path: "*", element: <Navigate to="/404" /> },
		],
	},
	{ path: "*", element: <Navigate to="/404" replace /> },
];

export default routers;
