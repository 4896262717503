
import {
    Modal,
    Box,
    Typography,
    Avatar
} from "@mui/material";

import UserModalButtons from './UserModalButtons'

const UserModal = ({user,handleClose,open, deleteUser}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
      
    const {uid,displayName,role,email,photoUrl} = user;
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                <Box
                    display="flex"
                    justifyContent="center"
                >
                     <Avatar alt={displayName} src={photoUrl} />
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginLeft:'10px'}}>
                        { displayName }
                    </Typography>
                </Box>

                <div style={{borderTop:'1px solid #ddd', margin:"30px 0 30px"}} />

                
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <strong>Role:</strong> { role }
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                    <strong>Email:</strong> { email }
                </Typography>

                <UserModalButtons id={uid} deleteUser={deleteUser} userName={displayName} closeUserModal={handleClose} />
            </Box>
        </Modal>
    )
}

export default UserModal;