export const VerifyErroCode = (errorCode) => {
	// fonte: https://firebase.google.com/docs/reference/js/firebase.auth.Auth
	// fonte: https://firebase.google.com/docs/auth/admin/errors?hl=pt-br
	switch (errorCode) {
		case "auth/app-deleted":
			return "The database was not found.";
		case "auth/expired-action-code":
			return "The action or link code has expired.";
		case "auth/invalid-action-code":
			return "The action code is invalid. This can happen if the code is malformed or has already been used.";
		case "auth/user-disabled":
			return "The user corresponding to the given credential has been deactivated.";
		case "auth/user-not-found":
			return "User not found.";
		case "auth/weak-password":
			return "The password is too weak.";
		case "auth/email-already-in-use":
			return "User email already in use!";
		case "auth/invalid-email":
			return "User email is invalid.";
		case "auth/operation-not-allowed":
			return "The account type corresponding to this credential is not yet activated.";
		case "auth/account-exists-with-different-credential":
			return "Email already associated with another account.";
		case "auth/auth-domain-config-required":
			return "Configuration for authentication was not provided.";
		case "auth/credential-already-in-use":
			return "An account for this credential already exists.";
		case "auth/operation-not-supported-in-this-environment":
			return "This operation is not supported in the running environment. Check if it must be http or https.";
		case "auth/timeout":
			return "Timeout!";
		case "auth/missing-android-pkg-name":
			return "A package name must be provided to install the Android application.";
		case "auth/missing-continue-uri":
			return "The next URL must be provided in the request.";
		case "auth/missing-ios-bundle-id":
			return "A package name must be provided to install the iOS application.";
		case "auth/invalid-continue-uri":
			return "The next URL provided in the request is invalid.";
		case "auth/unauthorized-continue-uri":
			return "The domain of the next URL is not whitelisted.";
		case "auth/invalid-dynamic-link-domain":
			return "The given dynamic link domain is not authorized or configured in the current project.";
		case "auth/argument-error":
			return "Check the link configuration for the application.";
		case "auth/invalid-persistence-type":
			return "The type specified for data persistence is invalid.";
		case "auth/unsupported-persistence-type":
			return "The current environment does not support the specified type for data persistence.";
		case "auth/invalid-credential":
			return "The credential has expired or is malformed.";
		case "auth/wrong-password":
			return "User Password is Incorrect.";
		case "auth/invalid-verification-code":
			return "The credential verification code is not valid.";
		case "auth/invalid-verification-id":
			return "The credential verification ID is not valid.";
		case "auth/custom-token-mismatch":
			return "The token is different from the requested pattern.";
		case "auth/invalid-custom-token":
			return "The token provided is not valid.";
		case "auth/captcha-check-failed":
			return "The reCAPTCHA response token is not valid, expired, or the domain is not allowed.";
		case "auth/invalid-phone-number":
			return "The phone number is in an invalid format (E.164 standard).";
		case "auth/missing-phone-number":
			return "Phone number is required.";
		case "auth/quota-exceeded":
			return "The SMS quota has been exceeded.";
		case "auth/cancelled-popup-request":
			return "Only one pop-up window request is allowed at a time.";
		case "auth/popup-blocked":
			return "The pop-up window was blocked by the browser.";
		case "auth/popup-closed-by-user":
			return "The popup window was closed by the user without completing the login to the provider.";
		case "auth/unauthorized-domain":
			return "The application domain is not authorized to perform operations.";
		case "auth/invalid-user-token":
			return "The current user was not identified.";
		case "auth/user-token-expired":
			return "User token is expired!";
		case "auth/null-user":
			return "The current user is null.";
		case "auth/app-not-authorized":
			return "Application not authorized to authenticate with the given key.";
		case "auth/invalid-api-key":
			return "The API key provided is invalid.";
		case "auth/network-request-failed":
			return "Failed to connect to the network.";
		case "auth/requires-recent-login":
			return "The user's last access time does not meet the security threshold.";
		case "auth/too-many-requests":
			return "Requests were blocked due to unusual activity. Please try again after some time.";
		case "auth/web-storage-unsupported":
			return "The browser does not support storage or if the user has disabled this feature.";
		case "auth/invalid-claims":
			return "Custom registration attributes are invalid.";
		case "auth/claims-too-large":
			return "The size of the request exceeds the maximum allowed size of 1 Megabyte.";
		case "auth/id-token-expired":
			return "The token entered has expired.";
		case "auth/id-token-revoked":
			return "The token entered has expired.";
		case "auth/invalid-argument":
			return "An invalid argument was given to a method.";
		case "auth/invalid-creation-time":
			return "The creation time must be a valid UTC date.";
		case "auth/invalid-disabled-field":
			return "The property for disabled user is invalid.";
		case "auth/invalid-display-name":
			return "The username is invalid.";
		case "auth/invalid-email-verified":
			return "The email is invalid.";
		case "auth/invalid-hash-algorithm":
			return "The HASH algorithm is not encryption supported.";
		case "auth/invalid-hash-block-size":
			return "The HASH block size is not valid.";
		case "auth/invalid-hash-derived-key-length":
			return "The size of the HASH-derived key is not valid.";
		case "auth/invalid-hash-key":
			return "The HASH key must have a valid byte buffer.";
		case "auth/invalid-hash-memory-cost":
			return "The HASH memory cost is not valid.";
		case "auth/invalid-hash-parallelization":
			return "Parallel loading of HASH is not valid.";
		case "auth/invalid-hash-rounds":
			return "HASH rounding is not valid.";
		case "auth/invalid-hash-salt-separator":
			return "The HASH generation algorithm SALT separator field must be a valid byte buffer.";
		case "auth/invalid-id-token":
			return "The token code entered is not valid.";
		case "auth/invalid-last-sign-in-time":
			return "Last login time must be a valid UTC date.";
		case "auth/invalid-page-token":
			return "The next URL provided in the request is invalid.";
		case "auth/invalid-password":
			return "The password is invalid, it must be at least 6 characters long.";
		case "auth/invalid-password-hash":
			return "Password HASH is not valid.";
		case "auth/invalid-password-salt":
			return "Password SALT is not valid.";
		case "auth/invalid-photo-url":
			return "The user photo URL is invalid.";
		case "auth/invalid-provider-id":
			return "The provider identifier is not supported.";
		case "auth/invalid-session-cookie-duration":
			return "The COOKIE duration of the session must be a valid number in milliseconds, between 5 minutes and 2 weeks.";
		case "auth/invalid-uid":
			return "The identifier provided must be a maximum of 128 characters.";
		case "auth/invalid-user-import":
			return "The user record to be imported is not valid.";
		case "auth/invalid-provider-data":
			return "The data provider is not valid.";
		case "auth/maximum-user-count-exceeded":
			return "The maximum number of users allowed to be imported has been exceeded.";
		case "auth/missing-hash-algorithm":
			return "It is necessary to provide the HASH generation algorithm and its parameters to import users.";
		case "auth/missing-uid":
			return "An identifier is required for the current operation.";
		case "auth/reserved-claims":
			return "One or more custom properties provided used reserved words.";
		case "auth/session-cookie-revoked":
			return "Session COOKIE has expired.";
		case "auth/uid-alread-exists":
			return "The provided identifier is already in use.";
		case "auth/email-already-exists":
			return "The provided email is already in use.";
		case "auth/phone-number-already-exists":
			return "The phone provided is already in use.";
		case "auth/project-not-found":
			return "No project was found.";
		case "auth/insufficient-permission":
			return "The used credential does not have permission to access the requested resource.";
		case "auth/internal-error":
			return "The authentication server encountered an unexpected error while trying to process the request.";
		default:
			return null;
	}
};
