import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import clockFill from "@iconify/icons-eva/clock-fill";
import { Box, Typography, ListItemText, ListItemButton } from "@mui/material";

import {
	getPendingNotification,
	updateAdminReadNoti,
} from "../../redux/actions/notificaiton";

function renderContent(notification) {
	const content = (
		<Typography variant="subtitle2">
			{notification && notification?.content}
			<Typography
				component="span"
				variant="body2"
				sx={{ color: "text.secondary" }}
			>
				{/* &nbsp; {noCase(notification.description)} */}
			</Typography>
		</Typography>
	);

	return {
		// avatar: <img alt={notification?.content} src={notification.avatar} />,
		title: content,
	};
}

NotificationItem.propTypes = {
	notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }) {
	const { title } = renderContent(notification);
	const handleUpdateRead = async (notificaiton) => {
		await updateAdminReadNoti(notificaiton?.id);
	};
	return (
		<ListItemButton
			to={`/dashboard/account/${notification.userId.substring(
				0,
				notification.userId.indexOf("-")
			)}`}
			disableGutters
			component={RouterLink}
			sx={{
				py: 1.5,
				px: 2.5,
				mt: "1px",
				...(notification?.read && {
					bgcolor: "action.selected",
				}),
			}}
		>
			<ListItemText
				primary={title}
				onClick={() => handleUpdateRead(notification)}
				secondary={
					<Typography
						variant="caption"
						sx={{
							mt: 0.5,
							display: "flex",
							alignItems: "center",
							color: "text.disabled",
						}}
					>
						<Box
							component={Icon}
							icon={clockFill}
							sx={{ mr: 0.5, width: 16, height: 16 }}
						/>
						{notification.createdDate?.toDate().toDateString()}
						{"  "}
						{
							notification.createdDate
								?.toDate()
								?.toTimeString()
								?.split(" ")[0]
						}
					</Typography>
				}
			/>
		</ListItemButton>
	);
}

const PendingNotificaiton = () => {
	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		const _fetchData = async () => {
			const data = await getPendingNotification();
			setNotifications(data);
		};
		_fetchData();
	}, []);

	return (
		<Box
			sx={{
				py: 2,
				px: 2.5,
			}}
		>
			<Typography variant="subtitle1">Pending Notifications</Typography>
			{notifications?.map((notification) => (
				<NotificationItem
					key={notification.id}
					notification={notification}
				/>
			))}
		</Box>
	);
};

export default PendingNotificaiton;
