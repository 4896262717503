import { Icon } from "@iconify/react";
// import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
// import peopleFill from "@iconify/icons-eva/people-fill";
// import settings from "@iconify/icons-eva/settings-2-outline";
import resource from "@iconify/icons-ant-design/book-outline";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
	// {
	// 	title: "dashboard",
	// 	path: "/dashboard/app",
	// 	icon: getIcon(pieChart2Fill),
	// },
	{
		title: "resources",
		path: "/dashboard/resource",
		icon: getIcon(resource),
	},
];

export default sidebarConfig;
