import { onAuthStateChanged } from "firebase/auth";
import {
	doc,
	collection,
	setDoc,
	updateDoc,
	query,
	serverTimestamp,
	getDocs,
	where,
	deleteDoc,
	orderBy,
} from "firebase/firestore";

import { auth, db } from "../../firebase-config";

export const getAllNotification = async () => {
	try {
		const notiRef = collection(db, "notifications");
		const q = query(notiRef, orderBy("createdDate", "desc"));
		const notisSnapshot = await getDocs(q);
		const notificationsList =
			notisSnapshot.docs.map((doc) => doc.data()) || [];

		return notificationsList;
	} catch (error) {
		console.log("her eis errro:", error);
	}
};
export const getUnreadNotification = async () => {
	try {
		const notiRef = collection(db, "notifications");
		const q = query(notiRef, where("admin_read", "==", false));
		const notisSnapshot = await getDocs(q);
		const notificationsList =
			notisSnapshot.docs.map((doc) => doc.data()) || [];
		return notificationsList;
	} catch (error) {
		console.log("her eis errro:", error);
	}
};

export const getPendingNotification = async () => {
	try {
		const notiRef = collection(db, "notifications");
		const q = query(notiRef, where("admin_read", "==", false));
		const notisSnapshot = await getDocs(q);

		const notificationsList = notisSnapshot.docs.map((doc) => doc.data());
		return notificationsList;
	} catch (error) {
		console.log("her eis errro:", error);
	}
};

export const getUserNotification = async () => {
	try {
		onAuthStateChanged(auth, async (currentUser) => {
			const notificationColRef = collection(
				db,
				"notifications",
				`${currentUser.uid}-newuser`
			);
			const q = query(notificationColRef, where("approval", "==", true));
			const querySnapshot = await getDocs(q);

			const notiData = querySnapshot.docs
				.map((doc) => {
					if (!doc.data()?.read) {
						return doc.data();
					}
					return null;
				})
				.filter((value) => value != null);

			const joinNotiColRef = collection(
				db,
				"notifications",
				`${currentUser.uid}-join`
			);
			const joinQ = query(joinNotiColRef, where("approval", "==", true));
			const queryJoinSnapshot = await getDocs(joinQ);

			const joinNotiData = queryJoinSnapshot.docs
				.map((doc) => {
					if (!doc.data()?.read) {
						return doc.data();
					}
					return null;
				})
				.filter((value) => value != null);
			const allNotificatons = notiData.concat(joinNotiData);

			return allNotificatons;
		});
	} catch (error) {
		console.log("her eis errro:", error);
	}
};

// Register User
export const createNotification = async (uid, content) => {
	try {
		// create new user in database

		await setDoc(doc(db, "notifications", uid), {
			content,
			read: false,
			userId: uid,
			approval: false,
			createdDate: serverTimestamp(),
		});
	} catch (err) {
		console.log("here is error", err);
	}
};

export const updateAdminReadNoti = async (uid) => {
	try {
		// update user in database
		await updateDoc(doc(db, "notifications", uid), {
			admin_read: true,
		});
	} catch (err) {
		console.log("here is error", err);
	}
};

export const updateNotification = async (uid, read, content) => {
	try {
		// update user in database
		await updateDoc(doc(db, "notifications", uid), {
			content,
			read,
			userId: uid,
			createdDate: serverTimestamp(),
		});
	} catch (err) {
		console.log("here is error", err);
	}
};

export const deleteNotification = async (uid) => {
	try {
		// delete user in database
		const userRef = doc(db, "notifications", uid);
		await deleteDoc(userRef);
	} catch (err) {
		console.log("here is error", err);
	}
};
