import { getAuth, GoogleAuthProvider } from "firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// production

const firebaseConfig = {
  apiKey: 'AIzaSyA9GarcpVvDLg_RR6GBJwMi7Nyn0l5anGs',
  authDomain: 'ssbu-e-resources.firebaseapp.com',
  projectId: 'ssbu-e-resources',
  storageBucket: 'ssbu-e-resources.appspot.com',
  messagingSenderId: '16948603311',
  appId: '1:16948603311:web:bc862611ec9016cb89e75a',
  measurementId: 'G-L2MBSRCPHZ'
};

// const firebaseConfig = {
// 	apiKey: "AIzaSyDzZwsbHdoJteI91ovz8TgcC_fBQyeDdLA",
// 	authDomain: "membernetwork-31599.firebaseapp.com",
// 	projectId: "membernetwork-31599",
// 	storageBucket: "membernetwork-31599.appspot.com",
// 	messagingSenderId: "579450353305",
// 	appId: "1:579450353305:web:ffa6217bd290ab256a6a08",
// 	measurementId: "G-MCDSPXVKHH",
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const provider = new GoogleAuthProvider();
export const auth = getAuth(app);
export const db = getFirestore(app);
