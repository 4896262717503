// material
import { Grid, Container, Card, Typography, Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
	getUserProfile,
	updateUserProfile,
	getAllUserFollowing,
	getUserMembers,
} from "../redux/actions/user";
// components
import Page from "../components/Page";
import AccountProfile from "../components/account/AccountProfile";
import UserFollowList from "./UserFollowList";
import UserMemberList from "./UserMemberList";

// ----------------------------------------------------------------------

const Account = ({ profile, getUserProfile, updateUserProfile }) => {
	const [userFollowing, setUserFollowing] = useState([]);
	const [userMembers, setUserMembers] = useState([]);
	// Get ID from URL
	const params = useParams();
	useEffect(() => {
		if (params.id) {
			getUserProfile(params.id);

			const Req = async () => {
				const data = await getAllUserFollowing(params.id);
				const membersData = await getUserMembers(params?.id);

				setUserFollowing(data);
				setUserMembers(membersData);
			};
			Req();
		}
		// eslint-disable-next-line
	}, [params.id]);

	return (
		<Page title="Dashboard: Account | SSBU">
			<Container>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						py: 8,
					}}
				>
					<Container maxWidth="lg">
						{userFollowing?.map((follow, index) => (
							<UserFollowList key={index} follow={follow} />
						))}

						<Typography sx={{ mb: 3 }} variant="h4">
							Account
						</Typography>
						<Grid container spacing={3}>
							<Grid item lg={4} md={6} xs={12}>
								<AccountProfile
									profile={profile}
									id={params.id}
									updateUserProfile={updateUserProfile}
								/>
							</Grid>
							<Grid item lg={8} md={6} xs={12}>
								<Box
									sx={{
										padding: "1rem 0rem",
									}}
								>
									<Typography>Members</Typography>
									<Divider />
								</Box>
								{userMembers?.length > 0 ? (
									userMembers.map((follow, index) => (
										<UserMemberList
											key={index}
											follow={follow}
										/>
									))
								) : (
									<p>No Member</p>
								)}
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Container>
		</Page>
	);
};

const mapStateToProps = (state) => ({
	profile: state.user.profile,
});
export default connect(mapStateToProps, { getUserProfile, updateUserProfile })(
	Account
);
