import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { getAuthUserProfile } from "../../redux/actions/auth";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
	display: "flex",
	minHeight: "100%",
	overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
	flexGrow: 1,
	overflow: "auto",
	minHeight: "100%",
	paddingTop: APP_BAR_MOBILE + 24,
	paddingBottom: theme.spacing(10),
	[theme.breakpoints.up("lg")]: {
		paddingTop: APP_BAR_DESKTOP + 24,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

// ----------------------------------------------------------------------

const DashboardLayout = ({ user, getAuthUserProfile }) => {
	const [open, setOpen] = useState(false);
	useEffect(() => {
		const getUser = async () => {
			await getAuthUserProfile();
		};
		getUser();
	}, []);

	return (
		<RootStyle>
			<DashboardNavbar onOpenSidebar={() => setOpen(true)} />
			<DashboardSidebar
				profile={user}
				isOpenSidebar={open}
				onCloseSidebar={() => setOpen(false)}
			/>
			<MainStyle>
				<Outlet />
			</MainStyle>
		</RootStyle>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});
export default connect(mapStateToProps, { getAuthUserProfile })(
	DashboardLayout
);
