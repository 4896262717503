// material
import { Grid, Container, Typography, Box } from "@mui/material";
// components
import Page from "../components/Page";
import { AccountProfileDetails } from "../components/account/AccountDetail";

// ----------------------------------------------------------------------

export default function CreateUser() {
	return (
		<Page title="Dashboard: Create User | SSBU">
			<Container>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						py: 8,
					}}
				>
					<Container maxWidth="lg">
						<Typography sx={{ mb: 3 }} variant="h4">
							Create User
						</Typography>
						<Grid container spacing={3}>
							<Grid item lg={8} md={6} xs={12}>
								<AccountProfileDetails />
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Container>
		</Page>
	);
}
