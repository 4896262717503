import { Grid, Card, Avatar, Typography, Stack, Link } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import { fetchUserProfile } from "../redux/actions/user";

const UserMembersList = ({ follow }) => {
	const [requester, setRequester] = useState({});

	useEffect(() => {
		const Requester = async () => {
			const data = await fetchUserProfile(follow?.requester);
			setRequester(data);
		};
		Requester();
		// eslint-disable-next-line
	}, [follow?.requester]);

	return (
		<Card
			style={{
				padding: "1rem",
				marginBottom: "1rem",
				background: "#f3fdff",
			}}
		>
			<Grid container spacing={2}>
				<Grid item md={6}>
					<Stack
						direction="row"
						spacing={1}
						alignItems="center"
						height="100%"
						style={{ marginLeft: ".5rem" }}
					>
						<Link
							to={`/dashboard/account/${requester?.uid}`}
							component={RouterLink}
						>
							<a>
								<Avatar
									sx={{ width: 24, height: 24 }}
									src={requester && requester?.photoUrl}
								/>
								<Typography>
									{requester?.displayName}
								</Typography>{" "}
							</a>
						</Link>
					</Stack>
				</Grid>
			</Grid>
		</Card>
	);
};

export default UserMembersList;
